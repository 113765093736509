import { useSelector } from 'react-redux';
import { LoggedInUserType, RdxStore } from '../rdx/ReduxTypes';
import { useEffect, useState } from 'react';
import { useDateHelper } from './useHelper';

type JwtTokenType = {
    ADMANAGER?: string
    ADMINISTRATOR?: string
    CONTENTADMINISTRATOR?: string
    DEVELOPER?: string
    SYSADMIN?: string
    aud?: string // "https://ohgarden.se/"
    auth_time: number // 1731672884
    exp: number // 1763208884
    "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier"?: string // "976a6151-3e96-4fc1-bfa3-5606a38e73b7"
    "https://ohgarden.se/claims/email"?: string // "jonas.oberg@gmail.com"
    "https://ohgarden.se/claims/garden"?: string // "b55c8bda-47e4-4245-b168-da002b0d156e"
    "https://ohgarden.se/claims/id"?: string // "976a6151-3e96-4fc1-bfa3-5606a38e73b7"
    "https://ohgarden.se/claims/name"?: string // Jenny & Jonas"
    "https://ohgarden.se/claims/organizations"?: string[] // ['ASDASDASd', 'QWEQWEQE']
    "https://ohgarden.se/claims/roles"?: string[] //  [ "DEVELOPER","ADMINISTRATOR","ADMANAGER","CONTENTADMINISTRATOR","SYSADMIN"]
    iss?: string // "https://ohgarden.se/"
    jti?: string // "5f3953de-95a3-4ad0-a8bd-c91b205433f9"
    sub?: string // "jonas.oberg@gmail.com"    
}

const parseJwt = (token: string): JwtTokenType => {
    try {
        var base64Url = token.split('.')[1];
        var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function (c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));
        return JSON.parse(jsonPayload);
    } catch (e) {
        return null
    }
}

export const useJwt = () => {
    const user: LoggedInUserType = useSelector((state: RdxStore) => state.app.loggedInUser);
    const [token, setToken] = useState<JwtTokenType>(null)

    useEffect(() => {
        if (!!user) {
            const jwt = user?.jwt;
            if (!!jwt) {
                setToken(parseJwt(jwt))
            }
        }
    }, [user]);

    const dh = useDateHelper();

    return {
        organizations: token?.['https://ohgarden.se/claims/organizations'],
        roles: token?.['https://ohgarden.se/claims/roles'],
        expiresEpoch: token?.exp,
        expires: dh.epochToDateTime(token?.exp),
    }


}