import React from "react"
import { Label } from "./Primitives"

export const EditStringInput = ({ title, defaultValue, onChange, placeholder, suggestions }: { title: string, defaultValue: string, onChange(e: string): void, placeholder?: string, suggestions?: string[] }) => {
    return (
        <div className="form-item w100">
            <Label text={title} />
            <input className="frm" type="text" defaultValue={defaultValue ?? ""} onChange={e => onChange(e.target.value)} placeholder={placeholder}
                list="suggestions"
            />
            <datalist id="suggestions">
                {suggestions?.map(s => (
                    <option value={s}>{s}</option>
                ))}
            </datalist>
        </div>
    )
}



export const EditTextArea = ({ title, defaultValue, onChange }: { title: string, defaultValue: string, onChange(e: string): void }) => {
    return (
        <div className="form-item w100">
            <Label text={title} />
            <textarea className="frm" defaultValue={defaultValue ?? ""} onChange={e => onChange(e.target.value)} />
        </div>
    )
}

export const EditCheckbox = ({ title, defaultValue, onChange, trueText = "Ja", falseText = "Nej" }: { title: string, defaultValue: boolean, onChange(e: boolean): void, trueText?: string, falseText?: string }) => {
    return (
        <EditSelect
            title={title}
            defaultValue={defaultValue ? "true" : "false"}
            onChange={e => onChange(e === "true")}
            options={[
                { value: "true", label: trueText },
                { value: "false", label: falseText },
            ]} />
    )
}

export type EditSelectOptionType = { label: string, value: string }
const EditSelect = ({ title, defaultValue, onChange, options }: { title: string, defaultValue: string, onChange(e: string): void, options: EditSelectOptionType[] }) => {
    return (
        <div className="form-item w100">
            <Label text={title} htmlFor={title} />
            <select className="frm" defaultValue={defaultValue} onChange={e => onChange(e.target.value)}>
                {options?.map(op => (
                    <option key={op?.value} value={op?.value}>{op.label}</option>
                ))}
            </select>
        </div>
    )
}
export const EditSelectInput = ({ title, defaultValue, onChange, options }: { title: string, defaultValue: string, onChange(e: string): void, options: EditSelectOptionType[] }) => {
    return (
        <div className="form-item w100">
            <Label text={title} htmlFor={title} />
            <select className="frm" defaultValue={defaultValue ? "true" : "false"} onChange={e => onChange(e.target.value)}>
                {options?.map(op => (
                    <option key={op?.value} value={op?.value}>{op.label}</option>
                ))}
            </select>
        </div>
    )
}
export const EditDateKeyInput = ({ title, defaultValue, onChange, placeholder }: { title: string, defaultValue: number, onChange(e: number): void, placeholder?: string }) => {

    const onCh = (e: string) => {
        if (!!e) {
            const stripped = e.replace(/\D/g, '');
            console.log("stripped: ", stripped)
            onChange(parseInt(stripped));
        } else {
            onChange(undefined)
        }
    };

    const s = (def: number) => {
        if (def?.toString()?.length === 8) {
            const y = def?.toString().substring(0, 4);
            const m = def?.toString().substring(4, 6);
            const d = def?.toString().substring(6, 8);

            const ret = y + "-" + m + "-" + d;
            console.log("ret : ", ret);
            return ret;
        } else {
            return null;
        }
    }




    return (
        <div className="form-item w100">
            <Label text={title} />
            <input className="frm" type="date" defaultValue={s(defaultValue) ?? ""} onChange={e => onCh(e.target.value)} placeholder={placeholder} />
        </div>
    )
}
