import React, { useEffect, useState } from "react"
import { AdClientType, AdItemType, AdItemTypeDefaultValue, AdItemTypeType, AdStateTypesAsList } from "./AdTypes";
import { useSelector, useDispatch } from 'react-redux';
import { RdxStore } from "../../rdx/ReduxTypes";
import { CHANGE_AD_ADGROUPNAMES, CHANGE_AD_ADS, CHANGE_AD_CLIENTS } from "../../rdx/adReducer";
import { FetchHandler } from "../../Handlers/FetchHandler";
import { MessageBoxes } from "../../Handlers/MessageBoxes";
import { StringHelper } from "../../Handlers/Helpers";

const adTypes: AdItemTypeType[] = [
    "discovery_featured",
    "discovery_featured_secondary",
    "calendar_list",
    "plantsearch",
    "plantdetail_main",
    "plantdetail_main_web",
    "monthly_article",

    "offer",
    // "discovery_featured_plants",
];
type AdAdminViewStates = "all" | "published";

type GroupedAdsType = {
    name: string,
    ids: string[],
    startDateKey: number,
    endDateKey: number
};

export const useAdAdmin = () => {

    const api = useAdApi();

    const adsStore: { [key: string]: AdItemType } = useSelector((state: RdxStore) => state.ads.ads);
    const uiClients = useAdClients();

    const dispatch = useDispatch();
    const [viewState, setViewState] = useState<AdAdminViewStates>("published");
    const [showArchived, setShowArchived] = useState<boolean>(false);
    const [listofIds, setListofIds] = useState<string[]>([]);
    const [listofClientIds, setListofClientIds] = useState<string[]>([]);
    const [clientsfFilter, setClientsfFilter] = useState<string[]>([]);

    const load = async () => {
        const cs: AdItemType[] = await api.getAds();
        let change = {};
        cs.forEach(c => change[c?.id] = c);
        dispatch({ type: CHANGE_AD_ADS, payload: change });
    }

    useEffect(() => {
        if (adsStore) {
            const ids = Object.keys(adsStore).map(key => key);
            const c = Array.from(new Set(Object.keys(adsStore).map(key => adsStore?.[key]?.clientId)));
            setListofIds(ids);
            setListofClientIds(c);
        }
    }, [, adsStore]);

    // newAd
    const [newAdModel, setNewAdModel] = useState<AdItemType>(AdItemTypeDefaultValue);
    const [newAdTypes, setNewAdTypes] = useState<AdItemTypeType[]>([]);
    const [newAdValid, setNewAdValid] = useState<boolean>(false);
    const newAdClear = async () => {
        setNewAdModel(AdItemTypeDefaultValue);
        setNewAdTypes([]);
        setNewAdValid(false);
    }

    // allAdsIds
    const [allAdsIds, setAllAdsIds] = useState<string[]>([]);
    useEffect(() => {
        const r = (viewState === "all" ? listofIds : listofIds?.filter(m => adsStore?.[m]?.published))?.filter(m => {
            if (clientsfFilter?.length > 0) {
                return clientsfFilter?.indexOf(adsStore?.[m]?.clientId) >= 0;
            } else {
                return true
            }
        });


        setAllAdsIds(r?.filter(m => showArchived ? true : !adsStore?.[m]?.archived));
    }, [, viewState, listofIds, adsStore, clientsfFilter, showArchived]);


    // allAdsIds grouped
    const [allGrouped, setAllGrouped] = useState<GroupedAdsType[]>(undefined);
    useEffect(() => {
        let grps: GroupedAdsType[] = [];
        if (allAdsIds) {
            allAdsIds.forEach(id => {
                const grp = !adsStore?.[id]?.adGroupName ? uiClients.get(adsStore?.[id]?.clientId)?.name : uiClients.get(adsStore?.[id]?.clientId)?.name + " - " + adsStore?.[id]?.adGroupName;

                if (grps.filter(m => m.name === grp)?.length <= 0) {
                    grps.push({
                        name: grp,
                        ids: [],
                        startDateKey: 0,
                        endDateKey: 0
                    });
                }

                grps.filter(m => m.name === grp).forEach(g => g.ids.push(id));
            });
            grps.forEach(g => {
                g.ids.forEach(id => {
                    const startDateKey = adsStore?.[id]?.startDateKey;
                    const endDateKey = adsStore?.[id]?.endDateKey;
                    //Fix undefined
                    if (!!startDateKey && !g.startDateKey) {
                        g.startDateKey = startDateKey;
                    }
                    if (!!endDateKey && !g.endDateKey) {
                        g.endDateKey = endDateKey;
                    }

                    // fix more or less
                    if (!!startDateKey && startDateKey < g.startDateKey) {
                        g.startDateKey = startDateKey;
                    }
                    if (!!endDateKey && endDateKey > g.endDateKey) {
                        g.endDateKey = endDateKey;
                    }
                })
            })
            setAllGrouped(grps);
        }
    }, [allAdsIds])



    useEffect(() => {
        let valid = true;
        if (!(newAdModel?.name?.length > 2)) { valid = false; }
        if (!(newAdModel?.clientId?.length > 10)) { valid = false; }
        if (!(newAdTypes?.length > 0)) { valid = false; }
        setNewAdValid(valid);
    }, [, newAdModel, newAdTypes])


    return {
        // ui 
        viewState: viewState, setViewState: setViewState,
        showArchived: showArchived, setShowArchived: setShowArchived,
        adTypes: adTypes,
        load: load,
        get: (id: string): AdItemType => adsStore?.[id],
        all: allAdsIds,
        allGrouped: allGrouped,
        allClientIdsUsed: listofClientIds,

        clientSelected: (id: string) => { return clientsfFilter?.indexOf(id) >= 0; },
        clientsfFilterToggle: (id: string) => {
            if (clientsfFilter.indexOf(id) >= 0) {
                setClientsfFilter(clientsfFilter.filter(m => m != id));
            } else {
                setClientsfFilter([...clientsfFilter, id]);
            }
        },
        // add,
        newAdTypes: newAdTypes, setNewAdTypes: setNewAdTypes,
        newAdModel: newAdModel,
        newAdValid: newAdValid,
        newAdSave: async () => {
            const postObj = newAdTypes.map(type => {
                const p: AdItemType = { ...newAdModel, type: type, adstate: "draft" };
                return p;
            });
            const asas = await FetchHandler.postJson("/api/ads/addmulti", postObj);
            newAdClear();
            load();
            setViewState("all");
        },
        newAdClear: newAdClear,
        newAdChange: async (item: AdItemType) => {
            const n = { ...newAdModel, ...item };
            console.log("model:", n)
            setNewAdModel(n);
        },
    }
}


// --------------------------------------------------------
// --------------------- useAdClients ---------------------
// --------------------------------------------------------
export const useAdClients = () => {
    const clients = useSelector((state: RdxStore) => state.ads.clients);
    const dispatch = useDispatch();
    const [listofIds, setListofIds] = useState<string[]>([]);

    const [newName, setNewName] = useState<string>("");

    useEffect(() => {
        if (clients) {
            const ids = Object.keys(clients).map(key => key);
            setListofIds(ids);
        }
    }, [, clients]);

    const load = async () => {
        const cs: AdClientType[] = await FetchHandler.getJson("/api/ads/clients");
        let change = {};
        cs.forEach(c => change[c?.id] = c);
        dispatch({ type: CHANGE_AD_CLIENTS, payload: change });
    }

    return {
        load: load,
        get: (id: string): AdClientType => clients?.[id],
        newName: newName,
        newClientUpdateName: (name: string) => { setNewName(name); },
        addNewClient: async () => {
            if (newName?.length > 3) {
                const asas = await FetchHandler.postJson("/api/ads/clients/add", { name: newName })
                setNewName("");
                await load();
            } else {
                MessageBoxes.warning("Namnet måste ara minst 4 tecken")
            }
        },
        all: listofIds
    }
}

export const useAdItemEditor = (id: string) => {
    const adsStore = useSelector((state: RdxStore) => state.ads.ads);
    const clients = useSelector((state: RdxStore) => state.ads.clients);
    const adGroupNames: { [key: string]: string[] } = useSelector((state: RdxStore) => state.ads.adGroupNames);
    const dispatch = useDispatch();

    const api = useAdApi();

    const item: AdItemType = adsStore?.[id];
    const [editItem, setEditItem] = useState<AdItemType>(item);
    const [editedFields, setEditedFields] = useState<AdItemType>({ id: id });
    const [changed, setChanged] = useState<boolean>(false);
    useEffect(() => {
        setEditItem(item)
        setChanged(false);
    }, [item])

    const client: AdClientType = clients?.[item?.clientId];

    const loadAll = async () => {
        const cs: AdItemType[] = await api.getAds();
        let change = {};
        let _adgroups = {};
        cs.forEach(c => {
            change[c?.id] = c;

            if (!_adgroups[c?.clientId]) {
                _adgroups[c?.clientId] = [];
            }
            if (!!c?.adGroupName && _adgroups[c.clientId].indexOf(c.adGroupName) < 0) {
                _adgroups[c.clientId].push(c?.adGroupName);
            }
        });
        dispatch({ type: CHANGE_AD_ADS, payload: change });
        dispatch({ type: CHANGE_AD_ADGROUPNAMES, payload: _adgroups });

    }

    return {
        adTypes: adTypes,
        adStates: AdStateTypesAsList,
        item: editItem,
        adGroupNames: adGroupNames,
        getItemPlantIds: (): string[] => {
            if (!editItem?.plantIds) {
                return [];
            }
            return (editItem?.plantIds).split(';')?.filter(m => StringHelper.isGuid(m));
        },
        allClients: Object.keys(clients ?? {}).map(key => clients?.[key]) as AdClientType[],
        clientName: client?.name,
        edit: (model: AdItemType) => {
            console.log("model: ", model)
            const e = { ...editItem, ...model };
            setEditedFields({ ...editedFields, ...model });
            setEditItem(e);
            setChanged(true);
        },
        changed: changed,
        save: async () => {
            const success = await api.updateAd(editedFields);
            await loadAll();
        },
        delete: async (id: string) => {
            const success = await api.deleteAd(id);
            await loadAll();
        },
        togglePublish: async (id: string, published: boolean) => {
            const success = await api.togglePublishAd(id, published);
            await loadAll();
        },
        toggleArchived: async (id: string, archived: boolean) => {
            const success = await api.toggleArchiveAd(id, archived);
            await loadAll();
        }

    }
}


const useAdApi = () => {

    return {
        getAds: async (): Promise<AdItemType[]> => {
            const cs: AdItemType[] = await FetchHandler.getJson("/api/ads");
            return cs;
        },
        updateAd: async (e: AdItemType): Promise<boolean> => {
            return await FetchHandler.patchJson("/api/ads/update", e);
        },
        deleteAd: async (id: string): Promise<boolean> => {
            return await FetchHandler.delete("/api/ads/" + id + "/delete");
        },
        togglePublishAd: async (id: string, published: boolean) => {
            return await FetchHandler.patchJson("/api/ads/" + id + "/publish", { published: published });
        },
        toggleArchiveAd: async (id: string, archived: boolean) => {
            return await FetchHandler.patchJson("/api/ads/" + id + "/archive", { archived: archived });
        },
    }
}