import { useLayoutEffect, useState } from "react"
import { useSelector, useDispatch } from 'react-redux';
import { SelectOptionType } from "../components/UI/UITYpes";
import { FetchHandler } from "../Handlers/FetchHandler";
import { CHANGE_UIACCOUNTSADMIN } from "../rdx/appReducer";
import { RdxStore, UIAccountsAdminType } from "../rdx/ReduxTypes";
import { useUsers } from "./useUsers";
import { UserRolesType } from "./MiscHooks";
import { Data } from "../Handlers/Data";

export type AccountStateType = "basic" | "premium";
export type SortingOptionsType = "trial" | "city" | "alias" | "created";
export type AccountType = {
    id: string,
    email: string,
    emailConfirmed: boolean,
    firstName: string,
    lastName: string,
    acceptsGdpr: boolean,
    emailForConfirmation: string,
    city: string,
    userCreatedUtc: string,
    premiumExpires: number,
    trialExpires: number,
    alias: string,
    state: AccountStateType,
    totalNumberOfHits: number,
    premiumSetBySubscription?: boolean,
    numberOfRoles: number
}
export type StoreUserSubscriptionType =
    {
        purchaseId: string,
        userId: string,
        currentState: string,
        productId: string,
        active: boolean,
        timeStampMillis: number,
        createdTimeStampMillis: number,
        lines: {
            logId: number,
            purchaseId: string,
            state: string,
            productId: string,
            notificationId: string,
            timeStampMillis: number
        }[]
    }

export const useAccountAdminUser = (userId: string) => {

    const [data, setData] = useState<StoreUserSubscriptionType[]>([])
    const load = async () => {
        const url = "api/accounts/" + userId + "/subs";
        let _d: StoreUserSubscriptionType[] = await FetchHandler.getJson(url);
        _d.sort((a, b) => {
            if (a?.createdTimeStampMillis > b?.createdTimeStampMillis) { return -1; }
            else if (a?.createdTimeStampMillis < b?.createdTimeStampMillis) { return 1; }
            else { return 0 }
        });
        setData(_d);
    }


    return {
        load: load,
        data: data,

    }
}

export const useAccountsAdmin = () => {

    const dispatch = useDispatch();
    const ui: UIAccountsAdminType = useSelector((state: RdxStore) => state.app.uiaccountsadmin);
    const users = useUsers();


    const dispatchtoRdx = (data: UIAccountsAdminType) => {
        dispatch({ type: CHANGE_UIACCOUNTSADMIN, payload: { ...ui, ...data } })
    }

    const [accounts, setAccounts] = useState<AccountType[]>([]);
    const [loading, setLoading] = useState<boolean>(false);

    const [selectedId, setSelectedId] = useState<string>(ui?.selectedId);

    const [accountType, setAccountType] = useState<string>(ui?.accountType);
    const [searchText, setSearchText] = useState<string>(ui?.searchText);
    const [page, setPage] = useState<number>(0);
    const [pageSize, setPageSize] = useState<number>(100);
    const [sort, setSort] = useState<SortingOptionsType>(ui?.sorting ?? "created");
    const [roles, setRoles] = useState<UserRolesType[]>(ui?.roles ?? []);

    const accountTypeoptions: SelectOptionType[] = [
        { value: null, label: "Ingen vald" },
        { value: "basic", label: "Basic" },
        { value: "trial", label: "Trial" },
        { value: "premium", label: "Premium" }
    ];
    const sortingOptions: SelectOptionType[] = [
        { value: null, label: "Ingen vald" },
        { value: "trial", label: "Trial avslutas" },
        { value: "city", label: "Ort" },
        { value: "alias", label: "Namn" },
        { value: "created", label: "Skapad" }
    ];
    const rolesOptions: SelectOptionType[] = [
        ...Object.keys(Data.roles).map(k => { return { value: k, label: k } })
    ];


    const loadFromFilters = async () => {
        setLoading(true);
        var url = "/api/accounts?" +
            "page=" + page + "&" +
            "pagesize=" + pageSize + "&" +
            "sort=" + sort + "&" +
            (!!accountType ? "type=" + accountType + "&" : "") +
            ("search=" + encodeURI(searchText ?? "") + "&") +
            (roles?.length > 0 ? "roles=" + encodeURI(roles.join(",")) + "&" : "");
        const users: AccountType[] = await FetchHandler.getJson(url);
        setAccounts(users);
        setLoading(false);
    }


    useLayoutEffect(() => {
        loadFromFilters();
    }, [accountType, searchText, sort, roles]);


    const numberOfAccounts = accounts?.[0]?.totalNumberOfHits ?? 0;
    const numberOfPages = Math.ceil(numberOfAccounts / pageSize) + 1;

    return {
        loading: loading,
        // UI
        page: page, setPage: setPage,
        nextPage: () => { setPage(page + 1); loadFromFilters(); },
        prevPage: () => { setPage(page === 0 ? 0 : page - 1); loadFromFilters(); },
        gotoPage: (idx: number) => { setPage(idx); loadFromFilters(); },
        pageSize: pageSize, setPageSize: setPageSize,
        numberOfPages: numberOfPages,
        // List of accounts
        accounts: accounts,
        numberOfAccounts: numberOfAccounts,

        // Dropdown options
        accountTypeoptions: accountTypeoptions,
        sortingoptions: sortingOptions,
        rolesOptions: rolesOptions,

        // Filtering
        setSelectedId: (e: string) => {
            setSelectedId(e);
            dispatchtoRdx({ selectedId: e })
        },
        accountTypeChanged: (e: SelectOptionType) => {
            setAccountType(e.value as string);
            dispatchtoRdx({ accountType: e.value as string })
        },
        sortingChanged: (e: SelectOptionType) => {
            setSort(e.value as SortingOptionsType);
            dispatchtoRdx({ sorting: e?.value as SortingOptionsType })
        },
        rolesChanged: (e: SelectOptionType[]) => {
            const r = e?.map(m => m?.value as UserRolesType);
            setRoles(r);
            dispatchtoRdx({ roles: r })
        },
        searchTextChanged: (e: string) => {
            setSearchText(e);
            dispatchtoRdx({ searchText: e })
            setPage(0);
        },
        selectedId: selectedId,
        accountType: accountType,
        sort: sort,
        searchText: searchText,
        roles: roles,
        resetToBasic: async (accountId: string): Promise<boolean> => {

            var url = "/api/accounts/resettobasic";

            const success = await FetchHandler.patchJson(url, {
                accountId: accountId
            });
            if (success === true) {
                loadFromFilters();
            }
            return success;
        },
        addOneMonthToTrial: async (accountId: string): Promise<boolean> => {
            var url = "/api/accounts/addonemonthtotrial";
            const success = await FetchHandler.patchJson(url, { accountId: accountId });
            if (success === true) {
                loadFromFilters();
            }
            return success;
        },
        expireTrial: async (accountId: string): Promise<boolean> => {
            var url = "/api/accounts/expiretrial";
            const success = await FetchHandler.patchJson(url, { accountId: accountId });
            if (success === true) {
                loadFromFilters();
            }
            return success;
        },
        saveTrialExpiresEpoch: async (accountId: string, epoch): Promise<boolean> => {
            var url = "/api/accounts/settrialexpiresepoch";
            const success = await FetchHandler.patchJson(url, { accountId: accountId, epoch: epoch });
            if (success === true) {
                loadFromFilters();
            }
            return success;
        },
        savePremiumExpiresEpoch: async (accountId: string, epoch): Promise<boolean> => {
            var url = "/api/accounts/setpremiumexpiresepoch";
            const success = await FetchHandler.patchJson(url, { accountId: accountId, epoch: epoch });
            if (success === true) {
                loadFromFilters();
            }
            return success;
        },

        // Users 
        ensureUserInfo: (id: string) => { users.ensureUserInfo([id]) },
        getUserInfo: users.getUser,
    }
}