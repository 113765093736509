import React, { useEffect, useState } from "react"
import { Link } from 'react-router-dom';
import { FetchHandler } from "../../Handlers/FetchHandler";
import { useDateHelper } from "../../hooks/useHelper";
import { AdminIcon, AdminIcons, AndroidIcon, IOsIcon } from "../Shared/AdminIcon";
import { CustomColumnContainer } from "../Shared/Formelements";
import { OsType } from "../Tools/ToolsScreen";
import { ScreenHeader } from "../UI/Elements";

export type ProductIdsType = "ohgarden.subs.year" | "ohgarden.subs.month";

type SubscriptionType = {
    alias: string,
    purchaseId: string,
    userId: string,
    active: true,
    currentState: string,
    productId: ProductIdsType,
    timeStampMillis: number,
    createdTimeStampMillis: number,
    premiumSetBySubscription: boolean,
    osType: OsType,
    trialExpires: number,
    userCreatedUtc: string
}
type ProductcountType = {
    "ohgarden.subs.year": number,
    "ohgarden.subs.month": number,
    android: number,
    ios: number,
};
export const Subscriptions = () => {

    const dte = useDateHelper();
    const [subscriptions, setSubscriptions] = useState<SubscriptionType[]>([]);
    const [productcount, setProductcount] = useState<ProductcountType>(null);

    const load = async (inactive?: boolean) => {
        const url = inactive ? "/api/subscriptions/inactive" : "/api/subscriptions";
        let data: SubscriptionType[] = await FetchHandler.getJson(url);

        let prods: ProductcountType = {} as ProductcountType;
        data.forEach(m => {
            const osType = m?.purchaseId?.length > 50 ? "android" : "ios";

            if (prods[m.productId]) {
                prods[m.productId]++;
            } else {
                prods[m.productId] = 1;
            }

            if (prods?.[osType]) {
                prods[osType]++;
            } else {
                prods[osType] = 1;
            }
            m.osType = osType;
        });
        setProductcount(prods);
        setSubscriptions(data);
    };

    useEffect(() => {
        load();
        let tc = setInterval(() => {
            load();
        }, 5 * 60 * 1000);
        return () => {
            clearTimeout(tc)
        }
    }, []);


    return (
        <div className="screen-subscriptions">
            <ScreenHeader text="Prenumerationer" icon={AdminIcons.dollarsign} />
            <div>
                <hr />
                <CustomColumnContainer gridTemplateColumns={"auto 300px 100px"}>
                    <div>
                        {productcount?.["ohgarden.subs.year"] + productcount?.["ohgarden.subs.month"]} prenumerationer, varav {productcount?.["ohgarden.subs.year"]} års- och {productcount?.["ohgarden.subs.month"]}  månadsprenumerationer. {" "}
                        <AndroidIcon />: {productcount?.android} st - <IOsIcon />: {productcount?.ios} st.
                    </div>
                    <div>
                        <button className="btn" onClick={() => {
                            load(true)
                        }}>
                            Inaktiva prenumerationer
                        </button>
                    </div>
                    <div>
                        <button className="btn" onClick={() => {
                            document.location.href = "/api/subscriptions/excel"
                        }}>
                            <AdminIcon icon={AdminIcons.excel} className="excel-icon" />
                        </button>
                    </div>
                </CustomColumnContainer>
                <hr />
                <table className="tbl">
                    <thead>
                        <tr>
                            <th></th>
                            <th></th>
                            <th>Alias</th>
                            <th>Köpt</th>
                            <th>Köpt tid</th>
                            <th>Trial går ut</th>
                            <th>Användare skapad</th>
                            <th>Produkt</th>
                            <th>Senast</th>
                            <th>Status</th>
                        </tr>
                    </thead>
                    <tbody>
                        {subscriptions.map((sub, idx) => (
                            <tr key={sub?.userId}>
                                <td>{idx + 1}.</td>
                                <td>
                                    {sub?.osType === "android" && (<AndroidIcon />)}
                                    {sub?.osType === "ios" && (<IOsIcon />)}
                                </td>
                                <td>
                                    <Link to={"/user/" + sub.userId}>
                                        {sub?.alias ? sub?.alias : "Användarnamn saknas"}
                                    </Link>
                                </td>
                                <td>{dte.timeAgo(sub?.createdTimeStampMillis / 1000)}</td>
                                <td>{dte.epochToDateTime(sub?.createdTimeStampMillis / 1000)}</td>
                                <td>{dte.epochToDate(sub?.trialExpires)}</td>
                                <td>{sub?.userCreatedUtc?.substring(0, 10)}</td>
                                <td style={{ textDecoration: !sub?.active ? "line-through" : null }}>{sub?.productId}</td>
                                <td>{dte.epochToDateTime(sub?.timeStampMillis / 1000)}</td>
                                <td>{sub?.currentState}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    )
}