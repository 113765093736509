import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { Link } from 'react-router-dom';
import { MessageBoxes } from "../Handlers/MessageBoxes";
import { CustomColumnContainer, FourColumnContainer, ThreeColumnContainer } from './Shared/Formelements';
import { AccountType, StoreUserSubscriptionType, useAccountAdminUser, useAccountsAdmin } from '../hooks/useAccountsAdmin';
import { useDateHelper } from '../hooks/useHelper';
import { LoadingBar, ScreenHeader } from './UI/Elements';
import { AdminIcon, AdminIcons } from './Shared/AdminIcon';
import { DateTime } from 'luxon';
import { UsersInfoType } from '../rdx/Redux.DataReducerType';
import { HasAnyRole } from './UI/RoleElements';
import { AccountActions } from './AccountsAdmin.Elements';
import { UserRolesType } from '../hooks/MiscHooks';

export const AccountsAdmin = () => {

    const accountsAdmin = useAccountsAdmin();
    // const helper = useDateHelper();


    return (
        <div className='view-accountsadmin'>
            <ScreenHeader text="Konton" icon={AdminIcons.users} />
            <FourColumnContainer>
                <div>
                    <label>Sök</label>
                    <SearchTextBox
                        onSearch={e => accountsAdmin.searchTextChanged(e)}
                        defaultValue={accountsAdmin.searchText}
                    />
                </div>
                <div>
                    <label>Kontotyp</label>
                    <Select
                        defaultValue={accountsAdmin.accountTypeoptions.find(m => m?.value == accountsAdmin?.accountType) ?? { value: "-", label: "Inget filter" }}
                        options={accountsAdmin.accountTypeoptions}
                        onChange={accountsAdmin.accountTypeChanged}
                    />
                </div>
                <div>
                    <label>Sortering</label>
                    <Select
                        defaultValue={accountsAdmin.sortingoptions.find(m => m?.value == accountsAdmin?.sort) ?? { value: "-", label: "Inget filter" }}
                        options={accountsAdmin.sortingoptions}
                        onChange={accountsAdmin.sortingChanged}
                    />
                </div>
                <div>
                    <label>Roller</label>
                    <Select
                        isMulti={true}
                        defaultValue={accountsAdmin.rolesOptions.filter(m => accountsAdmin?.roles?.indexOf(m?.value as UserRolesType) >= 0)}
                        options={accountsAdmin.rolesOptions}
                        onChange={accountsAdmin.rolesChanged}
                    />
                </div>
            </FourColumnContainer>
            <hr />

            <div></div>

            <div>
                <CustomColumnContainer gridTemplateColumns='150px 150px 150px 150px 150px 150px auto 55px'>
                    {/* <div>
                        <PageSelector
                            currentPage={accountsAdmin.page}
                            numberOfPages={accountsAdmin.numberOfPages}
                            nextClicked={accountsAdmin.nextPage} prevClicked={accountsAdmin.prevPage} />
                    </div> */}
                    <div>
                        <label>Träffar</label>
                        <div>
                            {accountsAdmin.numberOfAccounts}
                        </div>
                    </div>
                    <div>
                        {/* <label>Sidor</label>
                        <div>
                            {accountsAdmin.numberOfPages}
                        </div> */}
                    </div>
                    <div>
                        {/* <label>Sida</label>
                        <div>
                            {accountsAdmin.page + 1}
                        </div> */}
                    </div>
                    <div>
                        {/* <label>Sidstorlek</label>
                        <div>
                            {accountsAdmin.pageSize}
                        </div> */}
                    </div>


                    <div>
                        {/* <div>
                            <label>Föegående sida</label>
                            <div onClick={() => accountsAdmin.prevPage()}>
                                <AdminIcon icon={AdminIcons.arrowleft} className="fa-lg" />
                            </div>
                        </div> */}
                    </div>
                    <div>
                        {/* <div>
                            <label>Nästa sida</label>
                            <div onClick={() => accountsAdmin.nextPage()}>
                                <AdminIcon icon={AdminIcons.arrowright} className="fa-lg" />
                            </div>
                        </div> */}
                    </div>
                    <div></div>
                    <div>
                        <button className="btn" onClick={() => {
                            document.location.href = "/api/accounts/excel"
                        }}>
                            <AdminIcon icon={AdminIcons.excel} className="excel-icon" />
                        </button>
                    </div>
                </CustomColumnContainer>
            </div>
            <div>
                <LoadingBar loading={accountsAdmin.loading} />
            </div>
            <table className='tbl'>
                <thead>
                    <tr>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th>Alias</th>
                        <th>Email</th>
                        <th>Kommun</th>
                        <th>Skapad</th>
                        <th>Giltigt t.o.m.</th>
                        <th>Status</th>
                        <th>Premium t.o.m. </th>
                        <th>Trial t.o.m. </th>
                    </tr>

                </thead>
                <tbody>
                    {accountsAdmin.accounts && accountsAdmin.accounts.map((item, index) => (
                        <AccountRow key={item.id} rownumber={index + 1}
                            item={item}
                            selected={accountsAdmin.selectedId === item?.id}
                            onResetToBasic={async () => {
                                const success = await accountsAdmin.resetToBasic(item?.id);
                                if (success) {
                                    MessageBoxes.info("Avändaren återställd")
                                } else {
                                    MessageBoxes.warning("operationen misslyckades")
                                }
                            }}
                            addOneMonthToTrial={async () => {
                                const success = await accountsAdmin.addOneMonthToTrial(item?.id);
                                if (success) {
                                    MessageBoxes.info("Avändarens fick en månad till")
                                } else {
                                    MessageBoxes.warning("operationen misslyckades")
                                }
                            }}
                            expireTrial={async () => {
                                const success = await accountsAdmin.expireTrial(item?.id);
                                if (success) {
                                    MessageBoxes.info("Avändaren fick en månad till")
                                } else {
                                    MessageBoxes.warning("operationen misslyckades")
                                }
                            }}
                            onClickRow={() => {
                                accountsAdmin.setSelectedId(accountsAdmin.selectedId == item?.id ? null : item?.id);
                                accountsAdmin.ensureUserInfo(item?.id);
                            }}
                            onSaveTrialExpiresEpoch={async epoch => {

                                const success = await accountsAdmin.saveTrialExpiresEpoch(item?.id, epoch);
                                if (success) {
                                    MessageBoxes.info("Avändarens trialperiod ändrades")
                                } else {
                                    MessageBoxes.warning("operationen misslyckades")
                                }
                            }}
                            onSavePremiumExpiresEpoch={async epoch => {

                                const success = await accountsAdmin.savePremiumExpiresEpoch(item?.id, epoch);
                                if (success) {
                                    MessageBoxes.info("Avändarens trialperiod ändrades")
                                } else {
                                    MessageBoxes.warning("operationen misslyckades")
                                }
                            }}
                            userInfo={accountsAdmin.getUserInfo(item?.id)}
                        />))
                    }
                </tbody>
            </table>
        </div>
    )

}
const AccountStateIco = ({ item }: { item: AccountType }) => {

    if (item?.numberOfRoles > 0) {
        return (<AdminIcon icon={AdminIcons.role} />)
    } else {
        return (null);
    }

}
const AccountRow = (
    { item, selected, onClickRow, onResetToBasic, addOneMonthToTrial, expireTrial, onSaveTrialExpiresEpoch, onSavePremiumExpiresEpoch, rownumber, userInfo }:
        { item: AccountType, selected: boolean, onClickRow(): void, onResetToBasic(): void, addOneMonthToTrial(): void, expireTrial(): void, onSaveTrialExpiresEpoch(e: number): void, onSavePremiumExpiresEpoch(e: number): void, rownumber: number, userInfo?: UsersInfoType }
) => {
    const helper = useDateHelper();
    const stateClassname = "account-state-" + item?.state;

    const ui = useAccountAdminUser(item?.id);

    useEffect(() => {
        if (selected) {
            ui.load();
        }
    }, [, selected])


    return (
        <>
            <tr key={item.id} className={"account-row " + stateClassname} onClick={onClickRow}>
                <td>
                    {rownumber}
                </td>
                <td style={{ textAlign: "center" }}>
                    <AccountStateIco item={item} />
                </td>
                <td>
                    {(item?.state === "premium" && item?.premiumSetBySubscription === true) && (
                        <span title="Betalande användare">
                            <AdminIcon icon={AdminIcons.dollarsign} />
                        </span>
                    )}
                </td>
                <td>{item.alias}</td>
                <td>
                    <Link to={"/user/" + item.id}>
                        {item.email}
                    </Link>
                </td>
                <td>{item.city}</td>
                <td title={item.userCreatedUtc}>{item.userCreatedUtc?.substring(0, 10)}</td>
                <td style={{ width: "300px" }}><AccountExpires item={item} /></td>
                <td>{item.state}</td>
                <td>{helper.epochToDate(item.premiumExpires)}</td>
                <td>{helper.epochToDate(item.trialExpires)}</td>
            </tr>
            {selected && (
                <>
                    <tr className={'details-pane ' + stateClassname}>
                        <td colSpan={11}>
                            <CustomColumnContainer gridTemplateColumns="300px 150px 150px auto 150px" className='details-pane-grid' >
                                {/* 300px */}
                                <div style={{ display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
                                    <h3 style={{ flex: 0 }}>Alias: {item.alias}</h3>
                                    <div className='tagline' style={{ flex: 0 }}>
                                        <div>
                                            {item?.email}
                                        </div>
                                        <div>
                                            Gäller till:
                                            <AccountExpires item={item} />
                                        </div>
                                        <div>
                                            Kontotyp: {item?.state?.toUpperCase()}
                                        </div>
                                        <div>
                                            Trial dagar kvar: {helper.daysToEpoch(item.trialExpires)}
                                        </div>
                                        <div>
                                            Premium dagar kvar: {helper.daysToEpoch(item.premiumExpires)}
                                        </div>
                                        {/* <div>
                                            {userInfo?.userId}
                                        </div> */}

                                    </div>

                                    <div style={{ flex: 1 }}></div>

                                    <HasAnyRole roles={["SYSADMIN"]}>
                                        <AccountActions userId={item.id} email={item.email} />
                                    </HasAnyRole>
                                </div>

                                {/* 150px */}
                                <div>
                                    <EpochPicker
                                        label={"Trial upphör"}
                                        defaultValue={item?.trialExpires}
                                        onSave={epoch => {
                                            onSaveTrialExpiresEpoch(epoch)
                                        }}
                                    />
                                </div>

                                {/* 150px */}
                                <div>
                                    <EpochPicker
                                        label={"Premium upphör"}
                                        defaultValue={item?.premiumExpires}
                                        onSave={epoch => {
                                            onSavePremiumExpiresEpoch(epoch)
                                        }}
                                    />
                                </div>



                                {/* auto */}
                                <div className='subscriptions'>
                                    {ui.data?.filter(m => !!m?.active)?.map(sub => (
                                        <StoreUserSubscription sub={sub} key={sub.purchaseId} />
                                    ))}
                                    {ui.data?.filter(m => !m?.active)?.map(sub => (
                                        <StoreUserSubscription sub={sub} key={sub.purchaseId} />
                                    ))}
                                </div>

                                <div className='btn-lst'>
                                    <div>
                                        <ConfirmButton label='Återställ till basic konto' onClick={onResetToBasic} />
                                    </div>
                                    <div>
                                        <ConfirmButton label='Lägg till en månad på trial' onClick={addOneMonthToTrial} />
                                    </div>
                                    {/* <div>
                                        <ConfirmButton label='Gör trial utgången (igår)' onClick={expireTrial} />
                                    </div> */}
                                </div>
                            </CustomColumnContainer>
                        </td>
                    </tr>
                    <tr />
                </>
            )}
        </>
    )
}

const StoreUserSubscription = ({ sub }: { sub: StoreUserSubscriptionType }) => {

    const helper = useDateHelper();

    const [opened, setOpened] = useState<boolean>(false)

    return (
        <div className='subscription'>
            <div className='subscription-grid' onClick={() => { setOpened(!opened) }}>
                <div className='active'>
                    {sub.active ? "Aktiv" : "Ej aktiv"}
                </div>
                <div className='state'>
                    {sub.currentState}
                </div>
                <div className='product'>
                    {sub.productId}
                </div>
                <div className='timestamp'>
                    Skapad : {helper.epochToDateTime(sub.createdTimeStampMillis / 1000)}
                </div>
                <div className='open-notifications'>
                    <AdminIcon icon={opened ? AdminIcons.eye : AdminIcons.eyeclosed} />
                </div>
            </div>
            {!!opened && (
                <div className='notifications'>
                    {sub?.lines?.map(line => (
                        <CustomColumnContainer key={line?.logId} gridTemplateColumns="auto 150px 150px" className='notification'>
                            <div>
                                {line?.state}
                            </div>
                            <div>
                                {line?.productId}
                            </div>
                            <div className='timestamp'>
                                {helper.epochToDateTime(line?.timeStampMillis / 1000)}
                            </div>
                        </CustomColumnContainer>
                    ))}
                </div>
            )}
        </div>
    )
}

const ConfirmButton = ({ label, onClick, className }: { label: string, onClick(): void, className?: string }) => {

    const asdas = () => {
        if (window.confirm("SÄKER: " + label)) {
            onClick();
        }
    }

    return (
        <button onClick={asdas} className={className}>
            {label}
        </button>
    )
}

const AccountExpires = ({ item }: { item: AccountType }) => {
    const helper = useDateHelper();
    const dte = helper.epochToDate(Math.max(item.trialExpires, item.premiumExpires));
    return (
        <span>
            {dte}
        </span>
    )
}

const SearchTextBox = ({ defaultValue, onSearch }: { defaultValue?: string, onSearch(e: string): void }) => {
    const [text, setText] = useState(defaultValue ?? "");

    const onChange = (e) => {
        const val = e.target.value;
        setText(val);
    }

    const onEnter = () => {
        onSearch(text);
    }
    return (
        <input type="text" value={text}
            onChange={onChange}
            onKeyUp={(e) => { if (e.key === 'Enter') { onEnter(); }; }}
        //disabled={isSearching}
        />
    )
}

const EpochPicker = ({ label, defaultValue, onSave }: { label: string, defaultValue?: number, onSave(e: number): void }) => {

    const h = useDateHelper();
    const [epoch, setEpoch] = useState<number>(0);
    const [value, setValue] = useState<string>(null);

    const [editing, setEditing] = useState<boolean>(false)

    useEffect(() => {
        setValue(h.epochToDate(defaultValue))
    }, [, defaultValue]);

    const onSaveClick = () => {
        console.log("value: ", value);
        if (onSave) {
            onSave(epoch)
        }
        setEditing(false);
    }

    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const _epoch = DateTime.fromISO(e.target.value).toSeconds();
        setValue(e.target.value);
        setEpoch(_epoch);
    }
    return (
        <div className='datepicker-ctrl'>
            <div className='datepicker-ctrl-label'>{label}</div>
            {!editing && (
                <div className='datepicker-ctrl-view'>
                    <div className='datepicker-ctrl-value'>
                        {value}
                    </div>
                    <div className='datepicker-ctrl-edit-btn' onClick={() => setEditing(true)}>
                        <AdminIcon icon={AdminIcons.edit} />
                    </div>
                </div>
            )}
            {editing && (
                <div className='datepicker-ctrl-editor'>
                    <div>
                        <input type={"date"} value={value} onChange={onChange} className="datepicker" />
                    </div>
                    <div>
                        <button disabled={epoch < 1000} onClick={onSaveClick}>Spara</button>
                    </div>
                    <div>
                        <button disabled={epoch < 1000} onClick={() => {
                            setEpoch(0);
                            setValue(h.epochToDate(defaultValue));
                            setEditing(false);
                        }}>Ångra</button>
                    </div>
                </div>
            )}
        </div>
    )
}