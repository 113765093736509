import React, { useEffect, useState } from "react"
import { FetchHandler } from "../../Handlers/FetchHandler";
import { MessageBoxes } from "../../Handlers/MessageBoxes";
import { useReduxState } from "../../hooks/reduxHook";
import { AdminIcon, AdminIcons } from "../Shared/AdminIcon";
import { CustomColumnContainer, ThreeColumnContainer } from "../Shared/Formelements";
import { LoadingBar, ScreenHeader, ScreenMenu } from "../UI/Elements";
import { useStatisticsMenu } from "./useStatisticsMenu";




export const MiscStatistics = () => {

    const menu = useStatisticsMenu();

    return (
        <div>
            <ScreenHeader text="Statistik" icon={AdminIcons.chart} />
            <ScreenMenu items={menu?.items} />
            <MiscStats />

        </div>
    )
}

const MiscStats = () => {

    const ui = useMiscStats();

    return (
        <div>
            {/* <LoadingBar loading={ui.loading} /> */}
            <div className="data-card-container">
                <DataCard label="Tillagda växter" loading={ui.loading}>
                    <DataItem
                        label={"Senaste timmen"}
                        value={(ui.numberOfPlantsAddedToPlotsHour ?? "-") + " växter"}
                    />
                    <DataItem
                        label={"Senaste 24 timmar"}
                        value={(ui.numberOfPlantsAddedToPlotsDay ?? "-") + " växter"}
                    />
                    <DataItem
                        label={"Senaste 7 dagar"}
                        value={(ui.numberOfPlantsAddedToPlotsWeek ?? "-") + " växter"}
                    />
                </DataCard>


            </div>
        </div>
    )
}


const DataCard = ({ children, label, loading }: { children: any, label: string, loading?: boolean }) => {
    return (

        <div className={"data-card " + (loading ? "loading" : "")}>
            <div className="header">
                {label}
            </div>
            {children}
        </div>
    )
}

const DataItem = ({ label, value }: { label: string, value: string }) => {

    return (
        <div className="data-item">
            <label>{label}</label>
            <div className="value">
                {value}
            </div>
        </div>
    )
}



type NumberOfPlantsAddedToPlotType = {
    numberOfPlantsAddedToPlotsHour: number
    numberOfPlantsAddedToPlotsDay: number
    numberOfPlantsAddedToPlotsWeek: number
}


const useMiscStats = () => {

    const [loading, setLoading] = useState<boolean>(false);
    const [NumberOfPlantsAddedToPlot, setNumberOfPlantsAddedToPlot] = useState<NumberOfPlantsAddedToPlotType>(null);


    const loadNumberOfPlantsAddedToPlot = async () => {
        setLoading(true);
        var c = await FetchHandler.getJson("/api/stats/numberofplantsaddedtoplots");
        setNumberOfPlantsAddedToPlot(c);
        setLoading(false);
    }

    useEffect(() => {
        loadNumberOfPlantsAddedToPlot();
    }, []);

    return {
        loading: loading,
        numberOfPlantsAddedToPlotsDay: NumberOfPlantsAddedToPlot?.numberOfPlantsAddedToPlotsDay,
        numberOfPlantsAddedToPlotsHour: NumberOfPlantsAddedToPlot?.numberOfPlantsAddedToPlotsHour,
        numberOfPlantsAddedToPlotsWeek: NumberOfPlantsAddedToPlot?.numberOfPlantsAddedToPlotsWeek,
    }
}