import React, { ReactNode } from 'react';
import { SaveIcon } from './Icons';

export const FormTextInput = ({ title, invalid, name, value, onChange, className, style }:
  { title: string, invalid: boolean, name: string, value: string, onChange: any, className?: string, style?: any }) => {
  // 
  return (
    <div className={className ? "form-item " + className : "form-item"} style={style}>
      <label>
        {title}
        {invalid && (
          <span className="invalid-star">****</span>
        )}
      </label>
      <input
        type="text"
        name={name}
        value={value}
        onChange={onChange}
        className={invalid ? "invalid" : ""}
      />
    </div>
  );
}


export const FourColumnContainer = (props) => {
  return (<div className="fourcolumn-container">
    {props.children}
  </div>)
};

export const CustomColumnContainer = ({ children, visible, gridTemplateColumns, className }: { children: ReactNode, visible?: boolean, gridTemplateColumns: string, className?: string }) => {
  if (visible === false) {
    return (null);
  }

  return (<div className={"threecolumn-container" + (className ? " " + className : "")} style={{ gridTemplateColumns: gridTemplateColumns }}>
    {children}
  </div>)
};


export const ThreeColumnContainer = ({ children, visible, gridTemplateColumns }: { children: ReactNode, visible?: boolean, gridTemplateColumns?: string }) => {
  if (visible === false) {
    return (null);
  }

  return (<div className="threecolumn-container" style={{ gridTemplateColumns: gridTemplateColumns }}>
    {children}
  </div>)
};

export const TwoColumnContainer = (props) => {
  const classname = props.className ? "twocolumn-container " + props.className : "twocolumn-container";
  return (<div className={classname}>
    {props.children}
  </div>)
};
export const TwoOverThreeContainer = (props) => {
  const classname = props.className ? "twooverthree-container " + props.className : "twooverthree-container";
  return (<div className={classname}>
    {props.children}
  </div>)
};

export const ChildColumn = (props) => {
  return (<div className="childcolumn">
    {props.children}
  </div>)
};

export const PlantTag = (props) => {

  // states: 
  // save: option to save.

  let classes = null;
  let state = null;
  if (props.state) {
    state = props.state;
    classes += "plant-tag-save"
  }

  return (
    <span className={"plant-tag " + (classes ? classes : "")} onClick={props.onClick}>
      {props.tag}

      {state === "save" && (
        <SaveIcon />
      )}
    </span>
  )
};

export const TabContainer = ({ tabs, children, selectedTab, onClick }) => {
  return (
    <div className="tab-container">
      <div>
        {tabs.map(tab => (<TabLabel key={tab.key} {...tab} selected={selectedTab === tab.key} onClick={() => onClick(tab.key)} />))}
      </div>
      <div>
        {children}
      </div>
    </div>
  )
}
export const Tab = ({ label, children, visible, onClick }: { label?: string, children?: ReactNode, visible?: boolean, onClick?: any }) => {
  if (!visible) {
    return (null);
  }

  return (
    <div className="tab">
      {visible && (
        <div className="tab-content">
          {children}
        </div>
      )}
    </div>
  )
}

const TabLabel = (props) => {
  return (
    <div className={"tab-label " + (props?.selected ? "selected" : "")} onClick={props.onClick}>
      {props?.label}
    </div>
  )
}