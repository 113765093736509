import React, { useEffect, useRef, useState } from "react"
import { FetchHandler } from "../../Handlers/FetchHandler";
import { MessageBoxes } from "../../Handlers/MessageBoxes";
import { usePlant } from "../../hooks/usePlant";
import { SelectPlantsDialog } from "../Dialogs/SelectPlantsDialog";
import { EditDateKeyInput, EditSelectInput, EditStringInput } from "../FormComponents/EditInput";
import { AdminIcon, AdminIcons } from "../Shared/AdminIcon";
import { Dialog } from "../Shared/Dialog";
import { ThreeColumnContainer } from "../Shared/Formelements";
import { TagSelector } from "../Shared/TagSelector";
import { ToolBar, ToolBarClean } from "../ToolBar/ToolBar";
import { ScreenHeader } from "../UI/Elements"
import { Overlay } from "../UI/Overlay"
import { AdItemStatisticsType, AdItemTypeType, AdStateType } from "./AdTypes";
import { useAdAdmin, useAdClients, useAdItemEditor } from "./useAdAdmin";
import { DateFormatter } from "../../Handlers/Helpers";
import { UploadImageToAdElement } from "../FormComponents/UploadImageElement";

export const AdAdmin = () => {

    // Ensure clients
    const uiClients = useAdClients();
    useEffect(() => { uiClients.load(); }, [])

    const ui = useAdAdmin();

    useEffect(() => {
        ui.load();
    }, []);

    const [showAddAd, setShowAddAd] = useState<boolean>(false);
    const onAddAd = () => {
        setShowAddAd(true);
    }

    const [selectedId, setSelectedId] = useState<string>(null);

    const tools = [
        { id: "1", label: "Lägg till annons", onClick: onAddAd, icon: AdminIcons.plus },
        {
            id: "2",
            label: ui.viewState == "all" ? "Visa bara publicerade" : "Visa alla",
            onClick: () => ui.setViewState(ui.viewState == "all" ? "published" : "all"),
            icon: AdminIcons.eye
        },
        {
            id: "3",
            label: ui.showArchived ? "Visar arkiverade" : "Visar inte arkiverade",
            onClick: () => ui.setShowArchived(!ui.showArchived),
            icon: ui.showArchived ? AdminIcons.eye : AdminIcons.eyeclosed
        },
    ];

    return (
        <div>
            <Overlay visible={false} />
            <ScreenHeader text="Annonser" icon={AdminIcons.ad} />
            <div>
                <ToolBar tools={tools} />
            </div>
            <div>
                <div className="taglist">
                    {ui.allClientIdsUsed?.map(id => (
                        <span className={"tag" + (ui.clientSelected(id) ? " selected" : "")} key={id} onClick={() => { ui.clientsfFilterToggle(id); }}>
                            <span className="name">{uiClients.get(id)?.name}</span>
                            {/* <span className="num">{tag.num}</span> */}
                        </span>
                    ))}
                </div>
            </div>
            <div>
                <table className="tbl tbl-ads">
                    <thead>
                        <tr>
                            <th>Publicerad</th>
                            <th>Status</th>
                            <th>Annonsör</th>
                            <th>Namn</th>
                            <th>Typ</th>
                            <th>Period</th>
                            <th>Bild</th>
                            <th>Titel 1</th>
                            <th>Titel 2</th>
                            <th>Titel 3</th>
                        </tr>
                    </thead>
                    <tbody>
                        {ui?.allGrouped?.map((g, idx) => (
                            <React.Fragment key={g.name}>
                                <tr className="grp-row">
                                    <td colSpan={10}>
                                        <span className="grp-name">
                                            Projekt: {g.name}
                                        </span>
                                        <span className="grp-start">
                                            Start: {DateFormatter.dateKeyToDate(g.startDateKey)}
                                        </span>
                                        <span className="grp-end">
                                            Slut: {DateFormatter.dateKeyToDate(g.endDateKey)}
                                        </span>
                                    </td>
                                </tr>
                                {g?.ids?.map(a => (
                                    <Adrow
                                        key={a}
                                        id={a}
                                        open={selectedId === a}
                                        onRowClick={e => {
                                            if (selectedId === a) {
                                                setSelectedId(null);
                                            } else {
                                                setSelectedId(a);
                                            }
                                        }}
                                        reloadList={ui.load}
                                    />
                                ))}
                            </React.Fragment>
                        ))}
                    </tbody>
                </table>

            </div>

            {showAddAd && (
                <Dialog visible={showAddAd} title="Lägg till annons"
                    onOk={async () => {
                        await ui.newAdSave();
                        setShowAddAd(false);
                    }}
                    onCancel={() => {
                        ui.newAdClear();
                        setShowAddAd(false)
                    }}
                    okButtonText="Lägg till"
                    cancelButtonText="Ångra"
                    okDisabled={ui.newAdValid !== true}
                >
                    <div className="form-container">

                        <div className="form-item w100">
                            <label>
                                Namn
                            </label>
                            <input type="text" value={ui.newAdModel?.name} onChange={e => { ui.newAdChange({ name: e.target.value }) }} />
                        </div>

                        <div className="form-item w100">
                            <label>Annonsör</label>
                            <select
                                value={ui.newAdModel?.clientId}
                                onChange={e => { ui.newAdChange({ clientId: e.target.value }); }}                            >
                                <option key="">-</option>
                                {uiClients.all.map(option => (
                                    <option key={option} value={option}>{uiClients.get(option)?.name}</option>
                                ))}
                            </select>
                        </div>

                        <div className="form-item w100">
                            <label>Projektnamn</label>
                            <input type="text" value={ui.newAdModel?.adGroupName} onChange={e => { ui.newAdChange({ adGroupName: e.target.value }) }} />
                        </div>

                        <div className="form-item w100">
                            <label>Typ</label>
                            <div>
                                {ui.adTypes?.map(t => (
                                    <CheckBoxWithLabel
                                        key={t}
                                        checked={ui.newAdTypes.indexOf(t) >= 0}
                                        optionName={t}
                                        onChange={e => {
                                            if (ui.newAdTypes.indexOf(e) >= 0) {
                                                ui.setNewAdTypes(ui.newAdTypes.filter(m => m != e));
                                            } else {
                                                ui.setNewAdTypes([...ui.newAdTypes, e]);
                                            }
                                        }}
                                    />
                                ))}
                            </div>
                        </div>
                    </div>
                </Dialog>
            )}
        </div>
    )
}

const Adrow = ({ id, open, onRowClick, reloadList }: { id: string, open: boolean, onRowClick(e: string): void, reloadList(): void }) => {
    const uiClients = useAdClients();
    const ui = useAdAdmin();

    const item = ui.get(id);

    return (
        <>
            <tr onClick={() => onRowClick(id)} className={"tbl-expanded-row-toggler" + (!!item?.archived ? " archived" : "")}>
                <td className="singlerow">
                    {!!item?.published && (<AdminIcon icon={AdminIcons.eye} />)}
                    {!item?.published && (<AdminIcon icon={AdminIcons.eyeclosed} />)}
                </td>
                <td className={"adstate state-" + item?.adstate}>
                    {item?.adstate === "active" && (<AdminIcon icon={AdminIcons.checkbox_checked} />)}
                    {item?.adstate === "draft" && (<AdminIcon icon={AdminIcons.pen} />)}
                    {item?.adstate === "for_review" && (<AdminIcon icon={AdminIcons.search} />)}
                    {item?.adstate}
                </td>
                <td className="singlerow">
                    {uiClients.get(item?.clientId)?.name}
                </td>
                <td className="singlerow">
                    {item?.name}
                </td>
                <td>
                    {item?.type}
                </td>
                <td>
                    {DateFormatter.dateKeyToDate(item?.startDateKey)} - {DateFormatter.dateKeyToDate(item?.endDateKey)}
                </td>
                <td>
                    {!!item?.imageUrl && (<AdminIcon icon={AdminIcons.images} />)}
                </td>
                <td className="singlerow">
                    {item?.title01}
                </td>
                <td className="singlerow">
                    {item?.title02}
                </td>
                <td className="singlerow">
                    {item?.title03}
                </td>
            </tr>

            {open && (
                <>
                    <tr className={"tbl-expanded-row"}>
                        <td colSpan={9}>
                            <AdItemEditor id={id} reloadList={reloadList} />
                        </td>
                    </tr>
                    <tr />
                </>
            )}

        </>
    )

}


const AdItemEditor = ({ id, reloadList }: { id: string, reloadList(): void }) => {
    const editor = useAdItemEditor(id);

    const [statsData, setStatsData] = useState<AdItemStatisticsType>(null);

    const getStats = async () => {
        const data = await FetchHandler.getJson("/api/ads/" + id + "/statistics");
        setStatsData(data);
    }
    useEffect(() => {
        getStats();
    }, [])

    const toPercent = (e: number) => {
        if (e) {
            const n = Math.round(e * 1000) / 10;
            return n + "%";
        }
        return "-";
    }

    const [selectPlantsDialogVisible, setSelectPlantsDialogVisible] = useState<boolean>(false)

    const plantDb = usePlant();

    return (
        <div className={"ad-item-editor" + (!!editor?.item?.archived ? " archived" : "")}>
            <h3>
                Annonsör: {editor.clientName}
                {!!editor?.item?.archived && (
                    <span>&nbsp;(Arkiverad annons)</span>
                )}
            </h3>
            <div className="stats">
                Visningar: {statsData?.num_views ?? 0} st,
                Klick: {statsData?.num_press ?? 0} st,
                {/* Användare: {statsData?.num_users ?? 0} st, */}
                Användare (visa): {statsData?.num_users_view ?? 0} st,
                Användare (klick): {statsData?.num_users_press ?? 0} st,
                CTR: {statsData?.num_views > 0 ? toPercent(statsData?.num_press / statsData?.num_views) : "-"}
            </div>

            <div className="props">

                {/* Text */}
                <div>
                    <EditStringInput title={"Namn"} defaultValue={editor.item?.name ?? ""} onChange={e => { editor.edit({ name: e }) }} />
                    <EditStringInput title={"Titel 1"} defaultValue={editor.item?.title01 ?? ""} onChange={e => { editor.edit({ title01: e }) }} />
                    <EditStringInput title={"Titel 2"} defaultValue={editor.item?.title02 ?? ""} onChange={e => { editor.edit({ title02: e }) }} />
                    <EditStringInput title={"Titel 3"} defaultValue={editor.item?.title03 ?? ""} onChange={e => { editor.edit({ title03: e }) }} />
                    <EditStringInput title={"Titel 4"} defaultValue={editor.item?.title04 ?? ""} onChange={e => { editor.edit({ title04: e }) }} />
                    <EditStringInput title={"Titel 5"} defaultValue={editor.item?.title05 ?? ""} onChange={e => { editor.edit({ title05: e }) }} />
                </div>

                {/* Links & Images */}
                <div>
                    <EditStringInput title={"Länk (inkl https://)"} defaultValue={editor.item?.link ?? ""} onChange={e => { editor.edit({ link: e?.trim() }) }} />
                    <EditStringInput title={"Länktext"} defaultValue={editor.item?.linkText ?? ""} onChange={e => { editor.edit({ linkText: e }) }} />
                    <EditStringInput title={"Bild url (inkl https://)"} defaultValue={editor.item?.imageUrl ?? ""} onChange={e => { editor.edit({ imageUrl: e?.trim() }) }} />


                    {/* plantdetail_main */}
                    <div>
                        <div className="form-item w100" hidden={editor?.item?.type != "plantdetail_main"}>
                            <label>
                                Valda växter
                            </label>

                            <button className="btn select-plants-btn" onClick={e => { setSelectPlantsDialogVisible(true); }}>
                                Välj
                            </button>
                            <div>
                                {(!!editor.item?.plantIds) && editor.getItemPlantIds().map(pid => {
                                    const p = plantDb.item(pid);
                                    return (
                                        <div className="selected-plant-tag" key={pid}>
                                            {p?.fields?.name}
                                            {p?.fields?.specifictype}
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                        <SelectPlantsDialog
                            visible={selectPlantsDialogVisible}
                            preSelected={editor.item?.plantIds?.split(';')}
                            onPlantsSelected={(e: string[]) => {

                                if (e == null) { // cancel
                                    setSelectPlantsDialogVisible(false);
                                    console.log("cancel");
                                }
                                if (e?.length >= 0) { // ok
                                    setSelectPlantsDialogVisible(false);
                                    editor.edit({ plantIds: e.join(';') })
                                    console.log(e);
                                }
                            }} />
                    </div>
                </div>

                {/* Annonsör */}
                <div>

                    <div className="form-item w100">
                        <label>
                            Annonsör
                        </label>
                        <select
                            value={editor.item?.clientId}
                            onChange={(e) => { editor.edit({ clientId: e.target.value }); }}                            >
                            <option key="">-</option>
                            {editor.allClients.map(option => (
                                <option key={option?.id} value={option?.id}>{option?.name}</option>
                            ))}
                        </select>
                    </div>

                    <div className="form-item w100">
                        <label>
                            Typ
                        </label>
                        <select
                            value={editor.item?.type}
                            onChange={(e) => { editor.edit({ type: e.target.value as AdItemTypeType }); }}                            >
                            <option key="">-</option>
                            {editor.adTypes.map(option => (
                                <option key={option} value={option}>{option}</option>
                            ))}
                        </select>
                    </div>

                    <EditDateKeyInput title={"StartDatum"} defaultValue={editor.item?.startDateKey} onChange={e => { editor.edit({ startDateKey: e }) }} />
                    <EditDateKeyInput title={"SlutDatum"} defaultValue={editor.item?.endDateKey} onChange={e => { editor.edit({ endDateKey: e }) }} />
                    <EditStringInput title={"Projektnamn"} defaultValue={editor.item?.adGroupName} onChange={e => { editor.edit({ adGroupName: e }) }} suggestions={editor.adGroupNames?.[editor.item?.clientId]} />

                    <div className="form-item w100">
                        <label>
                            Status
                        </label>
                        <select
                            value={editor.item?.adstate}
                            onChange={(e) => { editor.edit({ adstate: e.target.value as AdStateType }); }}                            >
                            <option key="">-</option>
                            {editor.adStates.map(option => (
                                <option key={option} value={option}>{option}</option>
                            ))}
                        </select>
                    </div>
                </div>

                {/* Kopplad bild */}
                <div>

                    <UploadImageToAdElement
                        id={editor.item?.id}
                        onFileUploaded={reloadList}
                    />

                    <h4>Kopplad bild</h4>
                    <img src={editor?.item?.imageUrl} loading="lazy" />
                </div>

                {/* Tools */}
                <div>
                    <ToolBarClean
                        tools={[
                            {
                                id: "publish",
                                label: (editor?.item?.published ? "Avpublicera" : "Publicera"),
                                onClick: async () => {
                                    if (editor?.item?.published) {
                                        editor.togglePublish(editor?.item?.id, false);
                                    } else {
                                        editor.togglePublish(editor?.item?.id, true);
                                    }
                                },
                                icon: AdminIcons.save,
                                hidden: !!editor?.item?.archived
                            },
                            { id: "save", label: "Spara", onClick: editor.save, icon: AdminIcons.save, disabled: !editor.changed, hidden: !!editor?.item?.archived },
                            {
                                id: "delete", label: "Radera", onClick: async () => {
                                    const conf = MessageBoxes.confirm("Radera '" + editor?.item?.name + "'");
                                    if (conf) {
                                        const success = await editor.delete(editor?.item?.id);
                                    }
                                }, icon: AdminIcons.trashcan,
                                hidden: !!editor?.item?.archived
                            },
                            {
                                id: "archive",
                                label: (editor?.item?.archived ? "Ta ur arkiv" : "Arkivera"),
                                onClick: async () => {
                                    if (!!editor?.item?.archived) {
                                        editor.toggleArchived(editor?.item?.id, false);
                                    } else {
                                        editor.toggleArchived(editor?.item?.id, true);
                                    }
                                },
                                icon: AdminIcons.save,
                            },
                        ]}
                    />
                </div>
            </div>
        </div>
    )
}

const RadioButtonWithLabel = ({ optionName, onChange, label }: { optionName: AdItemTypeType, onChange(e: AdItemTypeType): void, label?: string }) => {

    return (
        <div className="checkbox-with-label">
            <input id={optionName} type={"radio"} name="type" onChange={() => onChange(optionName)} />
            <label htmlFor={optionName}>{label ?? optionName}</label>
        </div>
    )
}

const CheckBoxWithLabel = ({ checked, optionName, onChange, label }: { checked: boolean, optionName: AdItemTypeType, onChange(e: AdItemTypeType): void, label?: string }) => {

    return (
        <div className="checkbox-with-label">
            <input checked={checked} id={optionName} type={"checkbox"} name="type" onChange={() => onChange(optionName)} />
            <label htmlFor={optionName}>{label ?? optionName}</label>
        </div>
    )
}
