import React, { useState } from "react"
import { AdminIcons } from "../Shared/AdminIcon";
import { ThreeColumnContainer } from "../Shared/Formelements";
import { ScreenHeader } from "../UI/Elements";

export const Organizations = () => {


    const ui = useOrganizations();


    return (
        <div>
            <ScreenHeader text="Organisationer" icon={AdminIcons.house} />
            <div>
                <ThreeColumnContainer>
                    <div>
                        <label>Sök</label>
                        <input
                            placeholder="Sök..."
                            className="frm" type={"search"}
                            value={ui.search}
                            onChange={e => ui.setSearch(e.target.value)}
                            onKeyUp={e => {
                                if (e.key === "Enter") {
                                    ui.doSearch();
                                }
                            }}
                        />
                    </div>
                </ThreeColumnContainer>
            </div>
            <hr />
            <div>
                <table className="tbl tbl-small">
                    <thead>
                        <tr>
                            <th>N</th>
                            <th>N</th>
                            <th>N</th>
                            <th>N</th>
                            <th>N</th>
                        </tr>
                    </thead>
                    <tbody>
                        {[1, 2, 3, 4, 5, 6, 7, 8, 9, 0].map(n => (
                            <tr key={n}>
                                <td>{1 * n}</td>
                                <td>{2 * n}</td>
                                <td>{3 * n}</td>
                                <td>{4 * n}</td>
                                <td>{5 * n}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>

            </div>
        </div>
    )
}

const useOrganizations = () => {
    const [search, setSearch] = useState<string>("");
    return {
        search: search, setSearch: setSearch,
        doSearch: () => {
            setSearch("")
        },
    }
}