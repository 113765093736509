
import React from 'react';

export type AdminIconSize = "xs" | "sm" | "lg" | "2x" | "3x" | "5x" | "7x" | "10x"
export const AdminIcon = ({ icon, title, className, size, color }: { icon: string, title?: string, className?: string, size?: AdminIconSize, color?: string }) => {
    const sizeClass = size ? " fa-" + size : "";
    const classNameClass = className ? " " + className : "";
    const iconClass = " " + icon;
    return (
        <>
            <i className={"icon faicon " + iconClass + classNameClass + sizeClass} title={title} style={{ color: color }}></i>
        </>
    )
}

export const AndroidIcon = () => (<AdminIcon icon={AdminIcons.android} title="Android" color="#3DDC84" />);
export const IOsIcon = () => (<AdminIcon icon={AdminIcons.apple} title="IOs" color="#999" />);

// Get Icons from https://fontawesome.com/v5/search?o=r&m=free

export const AdminIcons = {
    ad: "fas fa-ad",
    android: "fab fa-android",
    angle_double_right: "fas fa-angle-double-right",
    apple: "fab fa-apple",
    arrowleft: "far fa-arrow-alt-circle-left",
    arrowright: "far fa-arrow-alt-circle-right",
    blog: "fas fa-rss-square",
    calendarCheck: "fas fa-calendar-check",
    chart: "fas fa-chart-bar",
    checkbox_checked: "far fa-check-square",
    checkbox_unchecked: "far fa-square",
    clipboard: "fas fa-clipboard-list",
    close: "fas fa-times",
    cogs: "fas fa-cogs",
    comment: "far fa-comment",
    comments: "far fa-comments",
    dollar: "fas fa-search-dollar",
    dollarsign: "fas fa-dollar-sign",
    edit: "far fa-edit",
    envira: "fab fa-envira",
    excel: "far fa-file-excel",
    externallink: "fas fa-external-link-alt",
    eye: "far fa-eye",
    eyeclosed: "far fa-eye-slash",
    fire: "fas fa-fire",
    globe: "fas fa-globe",
    hammer: "fas fa-hammer",
    heart: "fas fa-heart",
    help: "far fa-question-circle",
    house: "fas fa-home",
    image: "far fa-image",
    images: "far fa-images",
    leaf: "fas fa-leaf",
    link: "fas fa-link",
    list: "fas fa-list",
    mapmarker: "fas fa-map-marker-alt",
    palette: "fas fa-palette",
    paragraph: "fas fa-paragraph",
    pen: "fas fa-pen",
    plus: "fas fa-plus",
    posts: "fas fa-comment-alt",
    qrcode: "fas fa-qrcode",
    questionCircle: "far fa-question-circle",
    refresh: "fas fa-sync",
    refreshspin: "fas fa-sync fa-spin",
    ribbon: "fas fa-ribbon",
    role: "fas fa-user-tag",
    save: "far fa-save",
    search: "fas fa-search",
    signout: "fas fa-sign-out-alt",
    spa: "fas fa-spa",
    spinner: "fas fa-spinner fa-spin",
    toggle_off: "fas fa-toggle-off",
    toggle_on: "fas fa-toggle-on",
    toolbox: "fas fa-toolbox",
    trashcan: "fas fa-trash-alt",
    user: "fas fa-user",
    users: "fas fa-users",
    wifi: "fas fa-wifi",

}