import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { LoggedInUserType, RdxStore } from '../rdx/ReduxTypes';
import { useJwt } from './useJWT';

export const useUser = () => {
    const user: LoggedInUserType = useSelector((state: RdxStore) => state.app.loggedInUser);
    const jwt = useJwt();
    return {
        model: { ...user, roles: jwt?.roles },
        lkj: jwt?.organizations
    }
}