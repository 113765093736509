import React, { useEffect, useState } from "react"
import { FetchHandler } from "../../Handlers/FetchHandler";
import { MessageBoxes } from "../../Handlers/MessageBoxes";


export const UploadImageElement = ({ id, onFileUploaded }: { id: string, onFileUploaded(): void }) => {

    const [selectedFile, setSelectedFile] = useState<File>(undefined);
    const [previewUrl, setPreviewUrl] = useState<string>(undefined);
    const [uploading, setUploading] = useState<boolean>(false);
    const [currentId, set_id] = useState(id);

    useEffect(() => {
        setSelectedFile(undefined);
        setPreviewUrl(undefined)
        set_id(id);
    }, [id]);


    const onFileUpload = async () => {
        if (!currentId) {
            MessageBoxes.warning("Id is " + currentId);
            return;
        }
        setUploading(true);
        // Create an object of formData
        const formData = new FormData();

        // Update the formData object
        formData.append("image", selectedFile, selectedFile.name);

        const success = await FetchHandler.postForm("/api/poi/" + currentId + "/addimage", formData);

        if (onFileUploaded) {
            onFileUploaded();
        }

        setSelectedFile(undefined);
        setPreviewUrl(undefined);
        setUploading(false);

        if (!success) {
            MessageBoxes.warning("Uppladdningen misslyckades")
        }
    }

    return (
        <div className={"upload-container" + (uploading ? " uploading" : "")}>
            <div className="header" title={!!currentId ? "none" : currentId}>Ladda upp bilder</div>
            <div className="drop-zone">

                <div className="drop-zone-input">
                    <input type="file" onChange={e => {
                        const f = e?.target.files?.[0];
                        if (f) {
                            setSelectedFile(f);
                            setPreviewUrl(URL.createObjectURL(f))
                        }
                    }} />
                </div>
                <div className="drop-zone-image">
                    <img src={previewUrl} loading="lazy" />
                </div>
                {!!selectedFile && (
                    <div className="drop-zone-button">
                        <button className="btn" onClick={onFileUpload}>
                            Ladda upp
                        </button>
                    </div>
                )}

                <div className="drop-zone-label">
                </div>
            </div>
        </div>
    )
}


export const UploadImageToAdElement = ({ id, onFileUploaded }: { id: string, onFileUploaded(): void }) => {

    const [selectedFile, setSelectedFile] = useState<File>(undefined);
    const [previewUrl, setPreviewUrl] = useState<string>(undefined);
    const [uploading, setUploading] = useState<boolean>(false);
    const [currentId, set_id] = useState(id);

    useEffect(() => {
        setSelectedFile(undefined);
        setPreviewUrl(undefined)
        set_id(id);
    }, [id]);


    const onFileUpload = async () => {
        if (!currentId) {
            MessageBoxes.warning("Id is " + currentId);
            return;
        }
        setUploading(true);
        // Create an object of formData
        const formData = new FormData();

        // Update the formData object
        formData.append("image", selectedFile, selectedFile.name);

        const success = await FetchHandler.postForm("/api/ads/" + currentId + "/addimage", formData);

        if (onFileUploaded) {
            onFileUploaded();
        }

        setSelectedFile(undefined);
        setPreviewUrl(undefined);
        setUploading(false);

        if (!success) {
            MessageBoxes.warning("Uppladdningen misslyckades")
        }
    }

    return (
        <div className={"upload-container" + (uploading ? " uploading" : "")}>
            <div className="header" title={!!currentId ? "none" : currentId}>Ladda upp bilder</div>
            <div className="drop-zone">

                <div className="drop-zone-input">
                    <input type="file" onChange={e => {
                        const f = e?.target.files?.[0];
                        if (f) {
                            setSelectedFile(f);
                            setPreviewUrl(URL.createObjectURL(f))
                        }
                    }} />
                </div>
                {!!previewUrl && (
                    <div className="drop-zone-image">
                        <img src={previewUrl} loading="lazy" />
                    </div>
                )}
                {!!selectedFile && (
                    <div className="drop-zone-button">
                        <button className="btn" onClick={onFileUpload}>
                            Ladda upp
                        </button>
                    </div>
                )}

                <div className="drop-zone-label">
                </div>
            </div>
        </div>
    )
}