import { DateTime } from 'luxon';
import React, { Children, Component, ReactNode, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { BuildInfo } from '../../BuildInfo';
import { Data } from '../../Handlers/Data';
import { FetchHandler } from '../../Handlers/FetchHandler';
import { LocalStore } from '../../Handlers/LocalStore';
import { Routes /*, Views*/ } from "../../Handlers/ViewHandler";
import { useUserRoles } from '../../hooks/MiscHooks';
import { useBuild } from '../../hooks/useBuild';
import { useUser } from '../../hooks/useUser';
import store from '../../rdx/store';
import { useSignalRMessaging } from '../../rdx/useSignalR';
import { ApiBuildInfoType } from '../../Types';
import { AdminIcon, AdminIcons } from '../Shared/AdminIcon';
import { StartIcon, UsersIcon, AddPlantIcon, ImagesIcon, InspirationIcon, ColorIcon, FieldIcon } from "../Shared/Icons";
import { AdminColors } from './AdminColors';
import { HasAnyRole, HasRole } from './RoleElements';
import { SvgProfile } from './SvgResources/Icons';
export const LeftMenu = () => {

  const userId = useUser()?.model?.userId;


  return (
    <div id="ui-menu-left">
      {/* <div className="menu-header">
        Content
      </div> */}

      <HasAnyRole roles={["PLANTDATAADMIN", "ADMINISTRATOR"]}>

        <MenuSection label='Växter &amp; bilder'>
          <MenuItem to={Routes.start} text="Start" icon={(<StartIcon className="icon" />)} />
          <MenuItem to={Routes.addnewplant} text="Lägg till växt" icon={(<AddPlantIcon className="icon" />)} />
          <MenuItem to={Routes.allplantimages} text="Växtbilder" icon={(<AdminIcon icon={AdminIcons.images} />)} />
          <MenuItem to={Routes.createqrcode} text="Skapa QR kod" icon={(<AdminIcon icon={AdminIcons.qrcode} />)} />

        </MenuSection>

        <MenuSection label='Grunddata'>
          <MenuItem to={Routes.planttypes} text="Växttyper" icon={(<FieldIcon className="icon" />)} />
          <MenuItem to={Routes.plantfields} text="Växtfält" icon={(<AdminIcon icon={AdminIcons.comment} />)} />
          <MenuItem to={Routes.fieldspertype} text="Fält per typ" icon={(<AdminIcon icon={AdminIcons.link} />)} />
          <MenuItem to={Routes.lists} text="Listor" icon={(<AdminIcon icon={AdminIcons.list} />)} />
          <MenuItem to={Routes.snippets} text="Snippets" icon={(<AdminIcon icon={AdminIcons.paragraph} />)} />
          <MenuItem to={Routes.plantgroups} text="Samplantering" icon={(<AdminIcon icon={AdminIcons.leaf} />)} />
          {/* <MenuItem to={Routes.planttags} text="Taggar" icon={(<FieldIcon className="icon" />)} /> */}
          <MenuItem to={Routes.tools} text="Verktyg" icon={(<AdminIcon icon={AdminIcons.toolbox} />)} />
          <MenuItem to={Routes.colors} text="Färger" icon={(<AdminIcon icon={AdminIcons.palette} />)} />
          <MenuItem to={Routes.searchfilterseditor} text="Sökfilter" icon={(<AdminIcon icon={AdminIcons.search} />)} />
        </MenuSection>

        <HasAnyRole roles={["DEVELOPER"]}>
          <MenuSection label='Organisationer'>
            <MenuItem to={Routes.organizations} text="Organisationer" icon={(<AdminIcon icon={AdminIcons.house} />)} />
          </MenuSection>
        </HasAnyRole>

        <MenuSection label='Kartan'>
          <MenuItem to={Routes.pointofinterests} text="POIs" icon={(<AdminIcon icon={AdminIcons.globe} />)} />
        </MenuSection>

        <MenuSection label='UI Anpassningar'>
          <MenuItem to={Routes.gardenactivities} text="Aktiviteter" icon={(<AdminIcon icon={AdminIcons.calendarCheck} />)} />
          <MenuItem to={Routes.onboardinglists} text="Onboarding" icon={(<AdminIcon icon={AdminIcons.questionCircle} />)} />
          <MenuItem to={Routes.discover} text="Upptäck" icon={(<AdminIcon icon={AdminIcons.search} />)} />
          <MenuItem to={Routes.plantnetlist} text="PlantNet" icon={(<AdminIcon icon={AdminIcons.spa} />)} />




          <HasAnyRole roles={["DEVELOPER"]}>
            <MenuItem to={Routes.monthlyactivities} text="Månadsaktiviteter" icon={(<AdminIcon icon={AdminIcons.spa} />)} />
          </HasAnyRole>

        </MenuSection>

      </HasAnyRole>

      <MenuSection label='Användardata'>
        <HasRole role={"SYSADMIN"}>
          <MenuItem to={Routes.users} text="Användare" icon={(<AdminIcon icon={AdminIcons.users} />)} />
          <MenuItem to={Routes.userplants} text="Användarväxter" icon={(<AdminIcon icon={AdminIcons.leaf} />)} />
          <MenuItem to={Routes.feedback} text="Feedback" icon={(<AdminIcon icon={AdminIcons.comments} />)} />
          <MenuItem to={Routes.userstatus} text="Status" icon={(<AdminIcon icon={AdminIcons.users} />)} />
          <MenuItem to={Routes.userlists} text="Användarlistor" icon={(<AdminIcon icon={AdminIcons.users} />)} />
          <MenuItem to={Routes.faq} text="FAQ" icon={(<AdminIcon icon={AdminIcons.questionCircle} />)} />
        </HasRole>
      </MenuSection>

      <MenuSection label='Social'>
        <HasRole role={"SYSADMIN"}>
          <MenuItem to={Routes.comments} text="Kommentarer" icon={(<AdminIcon icon={AdminIcons.comments} />)} />

          <MenuItem to={Routes.forum} text="Forum" icon={(<AdminIcon icon={AdminIcons.comments} />)} />
          <MenuItem to={Routes.postsmanagement} text="Valda inlägg" icon={(<AdminIcon icon={AdminIcons.posts} />)} />

          {/* <MenuItem to={Routes.faq} text="FAQ" icon={(<FieldIcon className="icon" />)} /> */}
        </HasRole>


        <HasAnyRole roles={[]}>
          <MenuItem to={Routes.listitems} text="Partnersida" />
        </HasAnyRole>
      </MenuSection>


      {/* Bilder */}
      <MenuSection label='Bilder'>
        <HasRole role={"CONTENTADMINISTRATOR"}>
          {/* <MenuItem to={Routes.plantimageoperations} text="GAP Bilder" icon={(<AdminIcon icon={AdminIcons.images} />)} /> */}
          <MenuItem to={Routes.userplantimages} text="Nya växtbilder" icon={(<AdminIcon icon={AdminIcons.leaf} />)} />
          <MenuItem to={Routes.userplantimageoperations} text="Växt bilder" icon={(<AdminIcon icon={AdminIcons.images} />)} />
          <MenuItem to={Routes.socialimageoperations} text="Inläggs bilder" icon={(<AdminIcon icon={AdminIcons.images} />)} />
          {/* <MenuItem to={Routes.combines} text="Kombinationer" icon={(<AdminIcon icon={AdminIcons.fire} />)} /> */}
          {/* <MenuItem to={Routes.faq} text="FAQ" icon={(<FieldIcon className="icon" />)} /> */}
        </HasRole>
      </MenuSection>

      {/* Blog */}
      <HasRole role={"SYSADMIN"}>
        <MenuSection label='Blog & Web'>
          <MenuItem to={Routes.blog} text="Blog" icon={(<AdminIcon icon={AdminIcons.blog} />)} />
          <MenuItem to={Routes.blogauthors} text="Blog skribenter" icon={(<AdminIcon icon={AdminIcons.blog} />)} />
          <MenuItem to={Routes.webforms} text="Formulär" icon={(<AdminIcon icon={AdminIcons.comment} />)} />
        </MenuSection>
      </HasRole>

      {/* Annonser */}
      <HasRole role={"SYSADMIN"}>
        <MenuSection label='Annonser'>
          <MenuItem to={Routes.adadmin} text="Annonser" icon={(<AdminIcon icon={AdminIcons.ad} />)} />
          <MenuItem to={Routes.adclients} text="Annonsörer" icon={(<AdminIcon icon={AdminIcons.users} />)} />
        </MenuSection>
      </HasRole>

      {/* Konton & Premium */}
      <HasRole role={"SYSADMIN"}>
        <MenuSection label='Konton & Premium'>
          <MenuItem to={Routes.accountsadmin} text="Konton" icon={(<UsersIcon className="icon" />)} />
          <MenuItem to={Routes.subscriptions} text="Prenumerationer" icon={(<AdminIcon icon={AdminIcons.dollarsign} />)} />

          {/* <MenuItem to={Routes.subscriptionswithoutuser} text="Prenumerationer utan användare" icon={(<AdminIcon icon={AdminIcons.dollarsign} />)} /> */}


          <HasRole role={"DEVELOPER"}>
            <MenuItem to={Routes.purchases} text="Transaktioner" icon={(<AdminIcon icon={AdminIcons.dollar} />)} />
          </HasRole>
          {/* <MenuItem to={Routes.faq} text="FAQ" icon={(<FieldIcon className="icon" />)} /> */}
        </MenuSection>
      </HasRole>

      {/* Tests */}
      <MenuSection label='Tests'>
        <MenuItem to={Routes.elasticsearchplants} text="Elastic" icon={(<AdminIcon icon={AdminIcons.search} />)} />
        <MenuItem to={Routes.statistics} text="Statistik" icon={(<AdminIcon icon={AdminIcons.chart} />)} />
      </MenuSection>


      {/* Tech */}
      <HasRole role={"DEVELOPER"}>
        <MenuSection label='Tech'>

          <MenuItem to={Routes.largemap} text="Karta" icon={(<AdminIcon icon={AdminIcons.globe} />)} />
          {/* <MenuItem to={Routes.userstatistics} text="Anv. Statistik" icon={(<AdminIcon icon={AdminIcons.chart} />)} /> */}
          <MenuItem to={"user/" + userId} text="Jag" icon={(<AdminIcon icon={AdminIcons.user} />)} />
          <MenuItem to={Routes.systemsettings} text="Inställningar" icon={(<AdminIcon icon={AdminIcons.cogs} />)} />
          <MenuItem to={Routes.testscreen} text="Test" icon={(<AdminIcon icon={AdminIcons.cogs} />)} />
          {/* <MenuItem to={Routes.signalradmin} text="Aktivitet" icon={(<AdminIcon icon={AdminIcons.wifi} />)} /> */}


          {/* <MenuItem to={Routes.dashboard} text="Dashboard" icon={(<SvgProfile color={AdminColors.green10} className="icon" />)} /> */}
          {/* <MenuItem to={Routes.toolsscreen} text="Verktyg" icon={(<AdminIcon icon={AdminIcons.hammer} />)} /> */}
          <ReduxButton />


          <BuildInfoSection />

        </MenuSection>
      </HasRole>
    </div>
  );
}


const MenuSection = ({ label, children }: { label: string, children: ReactNode }) => {
  const key = "mnu:visible:" + label;
  const rawHidden: boolean = localStorage.getItem(key) ? JSON.parse(localStorage.getItem(key)) : false;
  const [hidden, setHidden] = useState<boolean>(rawHidden);

  return (
    <div>
      <div className='menu-section-label' onClick={() => {
        const newHidden = !hidden;
        localStorage.setItem(key, newHidden.toString());
        setHidden(newHidden);
      }}>
        {label}

        <AdminIcon icon={hidden ? AdminIcons.eyeclosed : AdminIcons.eye} />
      </div>
      {(hidden !== true) && (
        <ul>
          {children}
        </ul>
      )}
    </div>
  )
}

const ReduxButton = () => {
  return (
    <button onClick={() => { console.log("store.getState().data = ", store.getState()); }} className="w100">
      Redux
    </button>
  )
}


const BuildInfoSection = () => {

  const user = useUser();

  const [apiBuildDataStage, setApiBuildDataStage] = useState<ApiBuildInfoType>(null);
  const [apiBuildDataProd, setApiBuildDataProd] = useState<ApiBuildInfoType>(null);

  useEffect(() => {
    FetchHandler.getJson("https://apiohgarden-staging.azurewebsites.net/").then(buildDataStage => {
      setApiBuildDataStage(buildDataStage);
      console.log("buildDataStage:", buildDataStage);
    });

    FetchHandler.getJson("https://api.ohgarden.se/").then(buildDataProd => {
      setApiBuildDataProd(buildDataProd);
      console.log("buildDataProd:", buildDataProd);
    });
  }, []);


  return (
    <div style={{ textAlign: "center", fontSize: "12px", paddingTop: "10px" }}>
      <div>
        Release : {BuildInfo.BuildDateTimeString.substr(0, 10)} {BuildInfo.BuildDateTimeString.substr(11, 8)}
      </div>
      <div>
        BuildHash : {BuildInfo.BuildHash}
      </div>

      <div>
        api (prod)
      </div>
      <div>
        UTC {apiBuildDataProd?.build ? DateTime.fromISO(apiBuildDataProd?.build).toFormat("yyyy-LL-dd HH:mm:ss") : "-"}
      </div>
      <div>
        api (stage)
      </div>
      <div>
        UTC {apiBuildDataStage?.build ? DateTime.fromISO(apiBuildDataStage?.build).toFormat("yyyy-LL-dd HH:mm:ss") : "-"}
      </div>
    </div>
  )
}


const MenuItem = ({ to, text, icon }: { to: string, text: string, icon?: ReactNode }) => {
  const history = useHistory();
  const signalR = useSignalRMessaging();

  const handleOnClick = () => {
    signalR.ping(to);
    history.push(to);
  };


  return (
    <li className="clickable" onClick={handleOnClick}>
      <div>
        <span className="linktext">
          {icon}
          {text}
        </span>
      </div>
    </li>
  )
}