import React, { useEffect, useLayoutEffect, useState } from "react"
import Select from 'react-select';
import { useSelector, useDispatch } from 'react-redux';
import { AdminIcons } from "../Shared/AdminIcon"
import { ScreenHeader } from "../UI/Elements"
import { RdxStore, SearchFiltersSettingsType } from "../../rdx/ReduxTypes";
import { PlantFieldType, PlantTypeType } from "../../rdx/Redux.DataReducerType";
import { CHANGE_SEARCHFILTERS_SETTINGS } from "../../rdx/dataReducer";
import { FetchHandler } from "../../Handlers/FetchHandler";

export const SearchFiltersEditorScreen = () => {
    const ui = useSearchFiltersEditorScreen();

    useEffect(() => {
        ui.load();
    }, [])


    return (
        <div className="screen-searchfilters">
            <ScreenHeader text="Sökfilter" icon={AdminIcons.search} />

            <div>
                <button className="btn" onClick={ui.save} disabled={!ui.changed}>Spara</button>
            </div>
            <div>
                <hr />
                <table className="tbl-small">
                    <thead>
                        <tr>
                            <th>Namn</th>
                            {Array(ui.numcolumns).fill(null).map((u, i) => i).map(p => (
                                <th key={p}>
                                    Filter nr:{p + 1}
                                </th>
                            ))}
                        </tr>

                    </thead>
                    <tbody>
                        {ui.filters?.map(t => (
                            <tr key={t.plantTypeid}>
                                <td style={{ width: "200px" }}>
                                    {t?.name}
                                </td>

                                {Array(ui.numcolumns).fill(null).map((u, i) => i).map(p => {
                                    const localfields = ui.filters.find(m => m.plantTypeid.toLowerCase() === t.plantTypeid.toLowerCase()).fields;
                                    const n = localfields?.filter(m => !!m).length;
                                    console.log("n:", n);
                                    return (
                                        <td key={p}>
                                            {(p <= n) && (
                                                <DDL
                                                    plantTypeid={t.plantTypeid}
                                                    idx={p}
                                                    val={ui.filters.find(m => m.plantTypeid.toLowerCase() === t.plantTypeid.toLowerCase()).fields?.[p]}
                                                    plantfields={ui.plantfields}
                                                    onChange={e => ui.setValue(t.plantTypeid, p, e)}
                                                />
                                            )}
                                        </td>
                                    )
                                })}

                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            <hr />
            <div>
                <pre style={{ whiteSpace: "normal" }}>
                    {JSON.stringify(ui.getRawFilters())}
                </pre>
            </div>
        </div>
    )
}

const DDL = ({ plantTypeid, idx, val, plantfields, onChange }: { plantTypeid: string, idx: number, val: string, plantfields: PlantFieldType[], onChange(e: string): void }) => {

    const _id = plantTypeid + ":" + idx;
    const [optionIdx, setOptionIdx] = useState<number>(0);
    useLayoutEffect(() => {
        let i = 0;
        console.log("set val = " + val + " at idx : " + idx);
        i = (plantfields.findIndex(m => m?.id.toLowerCase() === val) + 1) ?? 0;
        //(document.getElementById(_id) as HTMLSelectElement).selectedIndex = i;
        setOptionIdx(Math.max(0, i));
        console.log(Math.max(0, i));
    }, [, val]);



    const ch = (v: string) => {
        onChange(v)
    }


    return (
        <div>

            <select
                id={_id}
                onChange={e => ch(e.target.value)}
                value={val}
            >
                <option selected={optionIdx === 0} value="">-</option>
                {plantfields.map((o, i) => (
                    <option selected={optionIdx === (i + 1)} key={o.id} value={o.id}>{o.name}</option>
                ))}

            </select>
        </div>
    )
}


const useSearchFiltersEditorScreen = () => {
    const planttypes: PlantTypeType[] = useSelector((state: RdxStore) => state.data.planttypes);
    const plantfields: PlantFieldType[] = useSelector((state: RdxStore) => state.data.plantfields)?.filter((m: PlantFieldType) => m?.typeString == "Color" || m?.typeString == "MultiSelect" || m?.typeString == "TrueFalse" || m.id == "ZONE")?.map(m => { return { ...m, id: m?.id?.toLowerCase() } });
    const searchFiltersSettings: SearchFiltersSettingsType[] = useSelector((state: RdxStore) => state.data.searchFiltersSettings);
    const dispatch = useDispatch();
    const [changed, setChanged] = useState<boolean>(false);

    const [filters, setFilters] = useState<SearchFiltersSettingsType[]>(searchFiltersSettings?.length > 5 ? searchFiltersSettings :
        planttypes?.map(r => {
            return {
                plantTypeid: r.id,
                name: r?.name,
                fields: []
            };
        })
    );

    const [numcolumns, setNumcolumns] = useState<number>(5);
    useEffect(() => {
        if (!!filters) {
            let n = 0
            filters.forEach(f => {
                if (n < f.fields.filter(m => !!m).length) {
                    n = f.fields.filter(m => !!m).length;
                }
            });

            setNumcolumns(n + 1);
        }

    }, [, filters]);



    const getRawFilters = () => {
        const r = [...filters.map(f => { return { plantTypeid: f.plantTypeid, fields: f.fields }; })];
        return r;
    }


    return {
        changed: changed,
        planttypes: planttypes,
        plantfields: plantfields,
        numcolumns: numcolumns,
        filters: filters,
        setValue: (type: string, idx: number, f: string) => {
            const findx = filters.findIndex(m => m.plantTypeid === type);
            let change = [...filters];
            change[findx].fields[idx] = f?.toLowerCase();
            change[findx].fields = change[findx].fields.filter(m => !!m);
            setFilters(change);
            setChanged(true);
        },
        getRawFilters: getRawFilters,
        save: async () => {

            var sdas = FetchHandler.patchJson("/api/searchfilters/config", { filters: filters });

            dispatch({ type: CHANGE_SEARCHFILTERS_SETTINGS, payload: filters });
            setChanged(false);
        },
        load: async () => {
            let reponse: { filters: SearchFiltersSettingsType[] } = await FetchHandler.getJson("/api/searchfilters/config");
            [...planttypes, { id: "generell", name: "Generellt" }].forEach(pt => {
                if (reponse?.filters.map(d => d.plantTypeid).indexOf(pt.id) < 0) {
                    reponse.filters.push({ plantTypeid: pt.id, name: pt.name, fields: [] })
                }
            });
            setFilters(reponse?.filters);
        }
    }
}

